import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

//components
import PageTitle from '../../components/common/pageTitle';
import { Content, EditContent } from '../../components/profile';
import { AlertMessage } from '../../components/common';
//style 
import style from '../../components/profile/profile.module.scss';

//service 
import ProfileService from '../../../service/profile';
import { withRouter } from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName';
import { useHistory } from 'react-router';
import Spinner from '../../shared/Spinner';

function ViewProfile({ t }) {
    let service = new ProfileService();
    let history = useHistory();

    const [state, setState] = useState({})
    const [notify,setNotify] = useState({
            show  :false,
            message : '',
            header : '',
            type : ''
    })
    const [loading, setLoading] = useState(false)
    const [checked,setChecked] = useState()


    const notifyInintialState = {
        show : false,
        message : '',
        type : '',
        header : ''
    }

    const notifyDissmissed = () =>{
    
        setNotify((prev)=>(
            {  
                ...prev,
            notify : notifyInintialState
            }
        ))
    }

    const getData = () => {
        service.getDetails().then((res) => {
            setLoading(true);
            if (res.status) {
                setState(
                    res.data
                )
                setChecked(
                    res.data.profile.auto_renewal
                )
                setLoading(false);
            } else {
                history.push('/logout');
            }
        })
    }

    const onDeletePic = event => {
        
        // if (window.confirm(t('validation.sureWantToDelete'))){
        //     console.log("======delteed conform")
        //   let service = new ProfileService();
    
        //   service.deleteImage().then(res=>{
        //       console.log(res)
    
        //     if(res.status){
        //       //getData();
             
        //     props.notify('success','Common.success','Common.profileDeletedSuccesfully');
    
        //     }else if(res.status === 404){
    
        //      props.notify('error','Common.success','Common.cantdeletedefaultpic');
    
        //     }
    
        //   })
          
        //   }
      };


    useEffect(() => {
        getData();
    
    }, []);

//subscription change

const handleSubscription = (e) =>{
    
if(e.target.checked){
    if (window.confirm(t("validation.sure_subscribe"))) {
        service.activateSubscription().then((res) => {
           
            if (res.status) {
                setChecked(
                    true
                )
                setNotify((prev)=>(
                    {  
                        ...prev,
                        show : true,
                        type : "success",
                        message : "subscription activated sucessfully",
                    }
                ))
            } else {
                setNotify((prev)=>(
                    {  
                        ...prev,
                        show : true,
                        type : "danger",
                        message : res.error.description,
                    }
                ))
            }
        })
        }
        else{
            setChecked(
                false
            )
        }
    
}
else{
    if (window.confirm(t("validation.sure_unsubscribe"))) {
        service.cancelSubscription().then((res) => {
       
            if (res.status) {
                setChecked(
                    false
                )
                setNotify((prev)=>(
                    {  
                        ...prev,
                        show : true,
                        type : "success",
                        message : "subscription cancelled sucessfully",
                    }
                ))
            } else {
                setNotify((prev)=>(
                    {  
                        ...prev,
                        show : true,
                        type : "danger",
                        message : res.error.description,
                    }
                ))
            }
        })
        }
        else{
            setChecked(
                true
            )
        }
  

}
}
      

        //after submit change change default value
     const  formSuccessfull = (newdata,key) =>{ 
            let fullname = '';
            let newMail = '';
            newdata.forEach(element => {
                const test = [...state.edit_fields[key].fields].map((object)=>{
                    if(object.code === element.code) {
                         return {
                            ...object,
                            value : element.value,
                        }
                        
                    }
                  
                    else return object;
                });
                setState(prevstate=>({
                    ...prevstate,
                    edit_fields : {
                        ...prevstate.edit_fields,
                        [key] : {
                            ...[key],
                            fields : test
                        }
                    }
                }))
                if(element.code === 'firstName' || element.code === 'lastName'){
                    fullname = fullname +' ' + element.value
                }else if(element.code === 'email'){
                    newMail= element.value
                }
                
            });
            if(key === 'personal_details'){
                fullname = fullname.substr(1)
                setState(prevstate=>({
                    ...prevstate,
                    profile : {
                        ...prevstate.profile,
                        full_name : fullname
                    }
                }))
            }else if(key === 'contact_details'){
                setState(prevstate=>({
                    ...prevstate,
                    profile : {
                        ...prevstate.profile,
                        email : newMail
                    }
                }))
            }
            getData();
        }
        if (loading){
            return (
                <Spinner/>
            )
        }
    else{
        return (

            <>
     
                <div className="h-100">
                    <Helmet>
                        <title>{companyName.companyName} | {t('Sidemenu.profile')}</title>
                    </Helmet>
                    <PageTitle
                        title="profile_view"
                        buttonOn={false} />
                    <div className={style.MainContainer}>
                    {
                notify.show &&
                <AlertMessage
                    show={notify.show}
                    message={notify.message}
                    dismiss={notifyDissmissed}
                    type={notify.type}
                />
            }
    
                        <Row>
                            <Col md={12} sm={12}>
                                {
                                    state.profile &&
                                    <Content
                                        profile={state.profile}
                                        extraData={state.extra_data}
                                        getData={getData}
                                        onDeletePic={onDeletePic}
                                        handleSubscription={handleSubscription}
                                        checked={checked}
                                    />
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    state.edit_fields &&
                                    <EditContent
                                        editData={state.edit_fields}
                                        updateSuccess={formSuccessfull}
                                        getData={getData}
                                        
                                   
                                    />
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )

    }

}
export default withTranslation()(withRouter(ViewProfile));