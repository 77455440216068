//const  navItems = ()=> {
  const navItems = [
    {
      title: 'network',
      icon: 'SettingsInputAntennaIcon',
      to: null,
      subMenuList: [{
        title: 'genealogyTree',
        icon: 'fa fa-circle',
        to: '/genealogyTree'
      },
      {
        title: 'sponsorTree',
        icon: 'fa fa-circle',
        to: '/sponsorTree'
      },
      {
        title: 'treeView',
        icon: 'fa fa-circle',
        to: '/treeView'
      },
      {
        title: 'downlineMembers',
        icon: 'fa fa-circle',
        to: '/downlineMembers'
      },
      {
        title: 'referralMembers',
        icon: 'fa fa-circle',
        to: '/referralMembers'
      }]
    },
  
  
    {
      title: 'register',
      icon: 'TouchAppOutlinedIcon',
      to: '/register',
      subMenuList: []
    },
  
  
    {
      title: 'ewallet',
      icon: 'AccountBalanceWalletOutlinedIcon',
      to: '/ewallet',
      subMenuList: []
    },
  
  
    {
      title: 'payout',
      icon: 'OutlinedFlagOutlinedIcon',
      to: '/payout',
      subMenuList: []
    },
  
  
    {
      title: 'epin',
      icon: 'PersonAddAlt1OutlinedIcon',
      to: '/epin',
      subMenuList: []
    },
  
  
    {
      title: 'shopping',
      icon: 'ShoppingBasketOutlinedIcon',
      to: '/shopping',
      subMenuList: []
    },
  
    {
      title: 'mailbox',
      icon: 'DraftsOutlinedIcon',
      to: '/mailbox',
      subMenuList: []
    },
  
  
    {
      title: 'tools',
      icon: 'SettingsOutlinedIcon',
      to: null,
      subMenuList: [{ title: 'replica_configuration', icon: 'fa fa-circle', to: '/replica_configuration' },
      { title: 'promotion_status', icon: 'fa fa-circle', to: '/promotion_status' },
      { title: 'downloadDocument', icon: 'fa fa-circle', to: '/downloadDocument' },
      { title: 'news', icon: 'fa fa-circle', to: '/news' },
      { title: 'faq', icon: 'fa fa-circle', to: '/faq' }]
    },
  
  
    {
      title: 'support',
      icon: 'SmsOutlinedIcon',
      to: '/support',
      subMenuList: []
    },
  
  
    {
      title: 'crm',
      icon: 'GroupsOutlinedIcon',
      to: null,
      subMenuList: [{ title: 'crmDashboard', icon: 'fa fa-circle', to: '/crmDashboard' },
      { title: 'addLead', icon: 'fa fa-circle', to: '/addLead' },
      { title: 'viewLead', icon: 'fa fa-circle', to: '/viewLead/All' },
      { title: 'graph', icon: 'fa fa-circle', to: '/graph' }]
    }
  ];
  // }
  export default navItems