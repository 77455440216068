import React, { useState, useEffect ,useRef} from 'react';
import { useParams ,Link } from "react-router-dom";
import LoginService from '../../../../service/Auth/Login'
import { Button } from "react-bootstrap";
import { Helmet } from 'react-helmet'
import companyName from '../../../../store/mobxStore/companyName'
import { useTranslation } from 'react-i18next'
import style from '../../../style/App.module.scss';
import {Container} from 'react-bootstrap';
import Brand from '../../../components/auth/Brand';




const RegisterVerification = ()=>{
    const {t} = useTranslation();
    const params = useParams();
    const [state,setState] =useState({
        text :"Please wait we are verifying..."
    })
    
    useEffect(()=>{
        const service = new LoginService();

        // const {username} = params.username;
        // const {adminName} = params.adminname;
        service.emailVerification(params.username,params.adminname)
         .then(res => {
            if(res.status) {
                    setState(()=>({
                        text : "Verification Done Successfully"
                    }))
            }
            else{
                setState(()=>({
                    text : "Error Occured"
                }))
            }
        })


    },[])


return(
    <>
            <div className="mb-2">
                <Helmet>
                    <title>{companyName.companyName} | {t('Common.resetPassword')}</title>
                </Helmet>
               

            
             <div className={`${style.app} pt-5`}>
            <Container className={style.loginContainer}>
            < Brand/>

                 <form id="forgot-form" style={{textAlign : "center"}}>
                
                  
               <span style={{fontWeight: "bold"}}>{state.text}</span>
               <br/><br/>
            <Button><Link to="/login"  style={{textDecoration : "none" , color : "white"}} className={style.backToDashboard}>
                   
                   Go to Login
                </Link></Button>
               
                </form>
                </Container>
                </div>
            </div>

        </>



)


}


export default RegisterVerification