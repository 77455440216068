import {BASE_URL,DEFAULT_KEY,LEAD_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';
import axios from 'axios';
import API from "../../api/api";

//let BASE_URL = "http://mlmadmin.demo5.iossmlm.com/api/";
export default class AppInfoservice{


    key =localStorage.getItem('apiKey')
    async Appinfo(){
        
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'common/app_info', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key?this.key:DEFAULT_KEY   // API key
                },
                // body: JSON.stringify(userData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    check_token(KeyData){
        console.log("keyData",KeyData)
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'common/check_token', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : localStorage.getItem('apiKey')  // API key
                },
                body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    get_layout(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/app_layout', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,   // API key
                    'access-token'  : UserStore.key
                },
                // body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    getToken(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/getToken', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,   // API key
                    // 'access-token'  : UserStore.key
                },
                // body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    validateString(code,key){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'auth/validateString', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,   // API key
                    'token'         : code,
                    'prefix'        : `${key}`
                },
                // body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    validateTreeString(title,string,key){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'auth/validateTreeString', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,   // API key
                    'token'         : string,
                    'prefix'        : `${key}`
                },
                // body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    getStoreUrl(title){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+`home/gotoStore?title=${title}`, {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,   // API key
                    'access-token'  : UserStore.key
                },
                // body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    getStoreUrl1 = async (title) => {
        return await API.API.get(`home/gotoStore?title=${title}`).then((res) => {
          if (res.status === 200) {
            return res.data;
          } else {
            return res;
          }
        });
      };
    //get the notification 
    async getNotification(){
        const response = await fetch(BASE_URL+'home/notificationCall', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const newsData = await response.json();
        return newsData ;
    }

    async getCountry(){
        const response = await fetch(`${BASE_URL}common/getAllCountry`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }


    addNewDemoVisitor(formData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/add_new_demo_visitor', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                 
                },
                body: JSON.stringify(formData)  //convert to json
                // body: formData  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    VerifyOtp(formData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/verifyotp', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(formData)  //convert to json

                // body: formData //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    ResendOtp(formData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/resendotp', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(formData)  //convert to json

                // body: formData  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    async logOut(){
        const response = await fetch(`${BASE_URL}common/logout`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key   
            }
        })
        const result = await response.json();
        return result;
    }

    checkDemoUsers(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/chckeDemo', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'access-token'  : UserStore.key ,
                    'api-key'       : this.key   // API key
                },
           
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    notificationClick(id){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/updateNotificationStatus', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,   // API key
                    'access-token'  : UserStore.key
                },
                // body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    notificationClick = async (id) => {
        return await API.API.get(`home/updateNotificationStatus?notification_id=${id}`).then((res) => {
          if (res.status === 200) {
            return res.data;
          } else {
            return res;
          }
        });
      };

}



